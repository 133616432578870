<template>
    <div>
        <fieldset>
            <div class="text-blue legend">
                {{ $t("serviceTicket.repairItem") }}
                <Tooltip placement="top">
                    <Icon type="ios-information-circle-outline" size="17" />
                    <div slot="content">
                        <div class="tw-text-xs">
                            {{ $t("serviceTicket.repairItem") }}
                        </div>
                    </div>
                </Tooltip>
            </div>

            <div
                class="tw-mt-2 overflow-auto tw-max-h-40"
                style="min-height: 400px"
            >
                <table class="tw-w-full tw-mt-2">
                    <thead>
                        <tr>
                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                style="width: 1%"
                            >
                                {{ $t("no") }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-left bg-header-main tw-border-gray-300"
                                style="width: 20%"
                            >
                                {{ $t("serviceTicket.product") }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-left bg-header-main tw-border-gray-300"
                                style="width: 20%"
                            >
                                {{ $t("serviceTicket.serial") }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                style="width: 135px"
                            >
                                {{ $t("serviceTicket.uom") }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                style="width: 135px"
                            >
                                {{ $t("serviceTicket.qty") }}
                            </th>

                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                            >
                                {{ $t("serviceTicket.warrantyExpiryDate") }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                style="width: 20%"
                            >
                                {{ $t("serviceTicket.remarks") }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                style="width: 3%"
                            >
                                <a
                                    href="#"
                                    class="tw-text-white"
                                    @click.prevent="addNewItem"
                                >
                                    <i class="fas fa-plus"></i>
                                </a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index) in model.product_list">
                            <tr :key="index" class="tw-align-top">
                                <td>
                                    <input
                                        type="text"
                                        :value="index + 1"
                                        class="tw-py-1 tw-px-2 tw-w-full tw-outline-none tw-text-center"
                                        readonly
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        v-model="item.product_name"
                                        class="form-control only-border-bottom"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'product_list.' +
                                                    index +
                                                    '.product_name'
                                            )
                                        }"
                                    />
                                    <div
                                        class="text-danger"
                                        v-if="
                                            errors.has(
                                                'product_list.' +
                                                    index +
                                                    '.product_name'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "product_list." +
                                                    index +
                                                    ".product_name"
                                            )
                                        }}
                                    </div>
                                </td>
                                <!-- <td>
                                    <Select
                                        v-model="item.product_id"
                                        :filter-by-label="true"
                                        :placeholder="$t('select')"
                                        :clearable="true"
                                        filterable
                                        @on-change="onChangeProduct"
                                    >
                                        <Option
                                            v-for="product in productList"
                                            :value="product.product_id"
                                            :key="product.product_id"
                                            :label="product.product_name_en"
                                        >
                                            {{ product.product_name_en }}
                                        </Option>
                                    </Select>
                                    <div
                                        class="text-danger"
                                        v-if="
                                            errors.has(
                                                'product_list.' +
                                                    index +
                                                    '.product_id'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'product_list.' +
                                                    index +
                                                    '.product_id'
                                            )
                                        }}
                                    </div>
                                </td> -->
                                <td>
                                    <input
                                        type="text"
                                        v-model="item.serial_no"
                                        class="form-control only-border-bottom"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'product_list.' +
                                                    index +
                                                    '.serial_no'
                                            )
                                        }"
                                    />
                                    <div
                                        class="text-danger"
                                        v-if="
                                            errors.has(
                                                'product_list.' +
                                                    index +
                                                    '.serial_no'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "product_list." +
                                                    index +
                                                    ".serial_no"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td>
                                    <Select
                                        v-model="item.uom_id"
                                        :placeholder="$t('select')"
                                        class="tw-text-center"
                                    >
                                        <Option
                                            v-for="uom in getUom(
                                                item.product_id
                                            )"
                                            :value="uom.uom_id"
                                            :key="uom.uom_id"
                                            :label="uom.uom_name_en"
                                        >
                                            {{ uom.uom_name_en }}
                                        </Option>
                                    </Select>
                                    <div
                                        class="text-danger"
                                        v-if="
                                            errors.has(
                                                'product_list.' +
                                                    index +
                                                    '.uom_id'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "product_list." +
                                                    index +
                                                    ".uom_id"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        v-model.number="item.quantity"
                                        class="form-control only-border-bottom tw-text-center"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'product_list.' +
                                                    index +
                                                    '.quantity'
                                            )
                                        }"
                                        placeholder="Qty"
                                    />
                                    <div
                                        class="text-danger"
                                        v-if="
                                            errors.has(
                                                'product_list.' +
                                                    index +
                                                    '.quantity'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "product_list." +
                                                    index +
                                                    ".quantity"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-py-1">
                                    <DatePicker
                                        :value="item.warranty_expiry_date"
                                        style="width: 100%"
                                        placeholder="DD-MM-YYYY"
                                        format="dd-MM-yyyy"
                                    ></DatePicker>
                                    <div
                                        class="text-danger"
                                        v-if="
                                            errors.has(
                                                'product_list.' +
                                                    index +
                                                    '.warranty_expiry_date'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "product_list." +
                                                    index +
                                                    ".warranty_expiry_date"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td>
                                    <textarea
                                        v-model.number="item.remarks"
                                        rows="1"
                                        class="form-control only-border-bottom"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'product_list.' +
                                                    index +
                                                    '.remarks'
                                            )
                                        }"
                                        placeholder="remarks"
                                    />
                                    <div
                                        class="text-danger"
                                        v-if="
                                            errors.has(
                                                'product_list.' +
                                                    index +
                                                    '.remarks'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "product_list." +
                                                    index +
                                                    ".remarks"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-text-center tw-align-middle">
                                    <a
                                        href="#"
                                        class="text-danger"
                                        @click.prevent="removeItem(item)"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </fieldset>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { clone } from "lodash";

export default {
    name: "ItemDetails",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            show_modal: false,
            model: {},
            newItem: {
                product_id: null,
                product_name: null,
                serial_no: null,
                uom_id: null,
                quantity: 1,
                warranty_expiry_date: null,
                repaired_by: null,
                completion_date: null,
                remarks: null
            }
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("customerService/serviceTicket", [
            "formViewDatas",
            "edit_data"
        ]),
        ...mapGetters("customerService/serviceTicket", [
            "getUom",
            "isDefaultUom"
        ]),
        productList() {
            return this.formViewDatas.product;
        }
    },
    methods: {
        onChangeProduct(value) {
            let uom_id = this.isDefaultUom(value);
            this.model.product_list.find(
                el => el.product_id == value
            ).uom_id = uom_id;
            return value;
        },
        addNewItem() {
            this.model.product_list.push(clone(this.newItem));
        },
        removeItem(item) {
            this.model.product_list.splice(
                this.model.product_list.indexOf(item),
                1
            );
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
<style scoped>
.legend {
    position: absolute;
    z-index: 200;
    top: 0;
    font-size: 12px;
    line-height: 1;
    margin: -13px 0 0; /* half of font-size */
    background: #fff;
    border: 1px solid #ddd;
    width: auto;
    padding: 5px 14px;
    box-shadow: -1px 4px 27px -7px #bdbdbd;
    border-radius: 3px;
    font-weight: bold;
}
.bg-header-main {
    background: #3990e0;
    color: white;
}
.bg-header-detail {
    background: #f1f1f1;
}
th {
    padding: 4px 8px;
}
td {
    padding: 3px 3px;
}
</style>
